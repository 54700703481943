"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
class MemoryCache {
  constructor(itemsExpirationCheckIntervalInSecs, maxItemCount) {
    this.itemsExpirationCheckIntervalInSecs = itemsExpirationCheckIntervalInSecs;
    this.maxItemCount = maxItemCount;
    this.itemKeyToValueWrapperMap = new Map();
    this.itemCount = 0;
    this.deleteExpiredItems = () => {
      const currentTimestampInMillisSinceEpoch = Date.now();
      const iterator = this.itemKeyToValueWrapperMap.entries();
      this.deleteExpiredItemsFromBatch(iterator, currentTimestampInMillisSinceEpoch);
    };
    this.timer = setInterval(this.deleteExpiredItems, itemsExpirationCheckIntervalInSecs * 1000);
  }
  storePermanentItem(key, value) {
    this.storeExpiringItem(key, value, 0);
  }
  storeExpiringItem(key, value, timeToLiveInSecs) {
    if (this.timer === null) {
      throw new Error('Cache is destroyed. Cannot store items anymore.');
    }
    if (this.itemCount < this.maxItemCount) {
      this.itemKeyToValueWrapperMap.set(key, {
        value,
        expirationTimestampInMillis: timeToLiveInSecs ? Date.now() + timeToLiveInSecs * 1000 : undefined
      });
      this.itemCount++;
    }
  }
  getItemCount() {
    return this.itemCount;
  }
  hasItem(itemKey) {
    return this.itemKeyToValueWrapperMap.has(itemKey);
  }
  getValues() {
    return Array.from(this.itemKeyToValueWrapperMap.values()).map(valueWrapper => valueWrapper.value);
  }
  getItems() {
    return Array.from(this.itemKeyToValueWrapperMap.entries()).map(([key, valueWrapper]) => [key, valueWrapper.value]);
  }
  retrieveItemValue(itemKey) {
    var _a;
    return (_a = this.itemKeyToValueWrapperMap.get(itemKey)) === null || _a === void 0 ? void 0 : _a.value;
  }
  getItemExpirationTimestampInMillisSinceEpoch(itemKey) {
    var _a;
    return (_a = this.itemKeyToValueWrapperMap.get(itemKey)) === null || _a === void 0 ? void 0 : _a.expirationTimestampInMillis;
  }
  removeItem(itemKey) {
    if (this.hasItem(itemKey)) {
      this.itemKeyToValueWrapperMap.delete(itemKey);
      this.itemCount--;
    }
  }
  clear() {
    this.itemKeyToValueWrapperMap.clear();
    this.itemCount = 0;
  }
  destroy() {
    if (this.timer) {
      this.clear();
      clearInterval(this.timer);
      this.timer = null;
    }
  }
  exportItemsToJson() {
    const itemsObject = Array.from(this.itemKeyToValueWrapperMap.entries()).reduce((itemsObject, [key, valueWrapper]) => ({
      ...itemsObject,
      [key]: valueWrapper
    }), {});
    return JSON.stringify(itemsObject);
  }
  importItemsFrom(json) {
    Object.entries(JSON.parse(json)).forEach(([key, {
      value,
      expirationTimestampInMillis
    }]) => {
      if (this.timer === null) {
        throw new Error('Cache is destroyed. Cannot store items anymore.');
      }
      if (this.itemCount < this.maxItemCount) {
        this.itemKeyToValueWrapperMap.set(key, {
          value,
          expirationTimestampInMillis
        });
        this.itemCount++;
      }
    });
  }
  deleteExpiredItemsFromBatch(iterator, currentTimestampInMillisSinceEpoch) {
    for (let i = 0; i < MemoryCache.EXPIRATION_PROCESSING_ITEM_BATCH_SIZE; i++) {
      const iteratorResult = iterator.next();
      if (iteratorResult.done) {
        return;
      }
      const [itemKey, valueWrapper] = iteratorResult.value;
      if (valueWrapper.expirationTimestampInMillis && valueWrapper.expirationTimestampInMillis < currentTimestampInMillisSinceEpoch) {
        this.itemKeyToValueWrapperMap.delete(itemKey);
        this.itemCount--;
      }
    }
    setImmediate(() => this.deleteExpiredItemsFromBatch(iterator, currentTimestampInMillisSinceEpoch));
  }
}
exports.default = MemoryCache;
MemoryCache.EXPIRATION_PROCESSING_ITEM_BATCH_SIZE = 100000;
